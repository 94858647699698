import * as volumeActions from '../actions/actionVolumePage';

const defaultState = {
  volumePage: {
    volumeCategories: [],
    vessels: [],
    partnerRegions: [],
    fuelCappingData: {},
    vessel_standard_rate: null,
    vessel_rob: null
  },
};

const REDUCERS = {
  [volumeActions.GET_VOLUME_CATEGORY]: (state, { payload }) => {
    return {
      ...state,
      volumePage: {
        ...state.volumePage,
        volumeCategories: payload,
      },
    };
  },

  [volumeActions.GET_TENANT_VESSELS]: (state, { payload }) => {
    return {
      ...state,
      volumePage: {
        ...state.volumePage,
        vessels: payload,
      },
    };
  },

  [volumeActions.GET_PARTNER_REGIONS]: (state, { payload }) => {
    return {
      ...state,
      volumePage: {
        ...state.volumePage,
        partnerRegions: payload,
      },
    };
  },

  [volumeActions.POST_FUEL_VOLUME_DATA]: (state, { payload }) => {
    return {
      ...state,
      volumePage: {
        ...state.volumePage,
      },
    };
  },
  [volumeActions.GET_VOLUME_BY_ID]: (state, { payload }) => {
    return {
      ...state,
      volumePage: {
        ...state.volumePage,
        volumeDataDetails: payload.data,
      },
    };
  },
  [volumeActions.PUT_VOLUME]: (state, { payload }) => {
    return {
      ...state,
      volumePage: {
        ...state.volumePage,
        updateVolumeDetails: payload,
      },
    };
  },

  [volumeActions.SET_STATE_VOLUME_PAGE]: (state, { payload }) => {
    return {
      ...state,
      volumePage: {
        ...state.volumePage,
        ...payload,
      },
    };
  },
  [volumeActions.GET_FUEL_CAPPING_DATA]: (state, { payload }) => {
    return {
      ...state,
      volumePage: {
        ...state.volumePage,
        fuelCappingData: payload.data,
      },
    };
  },
  [volumeActions.GET_LOCATIONS]: (state, { payload }) => {
    return {
      ...state,
      volumePage: {
        ...state.volumePage,
        ...payload,
      },
    };
  },
  [volumeActions.GET_VESSEL_STANDARD_RATE]: (state, { payload }) => {
    return {
      ...state,
      volumePage: {
        ...state.volumePage,
        vessel_standard_rate: payload,
      },
    };
  },
  [volumeActions.GET_VESSEL_ROB]: (state, { payload }) => {
    return {
      ...state,
      volumePage: {
        ...state.volumePage,
        vessel_rob: payload,
      },
    };
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
};

export const VOLUME_PAGE_REDUCER = 'volumePageReducer';

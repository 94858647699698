import { endOfMonth, startOfMonth } from 'date-fns'
import _ from 'lodash'
import moment from 'moment'
import * as actions from '../actions/actionOptimise'
import { getDisabledDatesByContract } from '../containers/views/optimise-page/util'
import { getSameDayVoyages, getPayloadVoyageChart } from '../utils/dataFormatUtil'
import { vesselScheduleStatus } from '../utils/vessel-schedule-status'

const defaultState = {
  optimisePage: {
    maintenanceEditStatus: {
      is_valid: false,
      message: '',
    },
    createNewSchedStatus: 'new',
    declinePublishDummy: false,
    saveNewCustomCVS: false,
    region: [],
    data: [],
    cvsServicesData: [], //NEW
    allowed_create: {},
    // postVesselScheduleAction: '',
    postVesselScheduleResponse: {},
    period: {
      startDate: moment(startOfMonth(new Date())).format('YYYY-MM-DD'),
      endDate: moment(endOfMonth(new Date())).format('YYYY-MM-DD'),
      key: 'selection',
    },
    bookMarineServiceStatus: {
      is_valid: true,
      message: '',
      scenario_id: '',
    },
    scenarioModellingDeleteStatus: '',
    scenarioModelling: [],
    schedulePlanner: {
      regionCode: '',
      regionType: '',
      period: {
        startDate: '',
        endDate: '',
        key: 'selection',
      },
      userRegionDropdown: [],
      data: [],
      chartDetails: [],
      listData: [],
      scheduleOverviewFooter: {},
    },
    vesselSchedule: {
      data: [],
      sideBar: {
        is_valid: true,
        message: '',
        data: {},
      },
      vesselScheduleFooter: {},
      regionCode: '',
      period: {
        startDate: '',
        endDate: '',
        key: 'selection',
      },
      fromDraft: false,
    },
    confirmSchedule: [],
    bookVessel: {
      period: {
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        key: 'selection',
      },
      locationList: [],
      startVesselList: [],
    },
    uploadData: {
      period: {
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        key: 'selection',
      },
      fileList: [],
      fileDetail: [],
      uploadStatus: {},
    },
    material_logistics: [],
    people_logistics: [],
    marine_services: [],
    openedTabs: [],
    dateRangeByRegionCode: [],
    supplyBaseByRegionCode: [],
    rovingServiceType: [],
    vesselJobType: [],
    businessUnit: [],
    underDeckCargoDropdown: [],
    vesselDropdownList:[],
    vesselCapacity: [],
    vesselContractPeriod: {},
    scheduleComparison: [],
    scheduleMonth: [],
    supplyBaseLocationList: [],
    publishVesselSuccess: '',
    publishVesselMessage: '',
    newVoyageDetails: {
      jobType: '',
      vesselName: '',
      vesselId: '',
    },
    addVoyageStatus: {
      isValid: false,
      message: '',
    },
    deleteVoyageStatus: {
      isValid: false,
      message: '',
    },
    saveCustomScenarioStatus: '',
    saveScenarioStatus: '',
    newSideBarDataRequest: {},
    demandForceCast: [],
    compareScheduleData: {},
    listRunIdD3Optimization: [],
    voyagesDMinus3Status: {},
    isPLTIntegration: false,
  },
  activityLogs: []
}

const REDUCERS = {
  [actions.RESET_OPTIMISE_PAGE]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: defaultState.optimisePage,
    }
  },
  [actions.GET_VESSEL_SCHEDULE]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        data: _.get(payload, 'data'),
        cvsServicesData: _.get(payload, 'data'),
        allowed_create: _.get(payload, 'allowed_create'),
        material_logistics: _.get(_.get(payload, 'data'), 'material_logistics') || [],
        people_logistics: _.get(_.get(payload, 'data'), 'people_logistics') || [],
        marine_services: _.get(_.get(payload, 'data'), 'marine_services') || [],
      },
    }
  },
  [actions.GET_SCENARIO_MODELLING]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        scenarioModelling: payload,
      },
    }
  },
  [actions.GET_USER_REGION_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        region: _.get(payload, 'region'),
        schedulePlanner: {
          ...state.optimisePage.schedulePlanner,
          userRegionDropdown: _.get(payload, 'region'),
        },
      },
    }
  },
  [actions.GET_SCHEDULE_PLANNER_CHART]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        schedulePlanner: {
          ...state.optimisePage.schedulePlanner,
          data: payload,
        },
      },
    }
  },
  [actions.GET_SCHEDULE_PLANNER_CHART_DETAILS]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        schedulePlanner: {
          ...state.optimisePage.schedulePlanner,
          chartDetails: payload,
        },
      },
    }
  },
  [actions.GET_SCHEDULE_OVERVIEW_FOOTER]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        schedulePlanner: {
          ...state.optimisePage.schedulePlanner,
          scheduleOverviewFooter: payload,
        },
      },
    }
  },
  [actions.GET_CONFIRM_SCHEDULE]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        confirmSchedule: payload,
      },
    }
  },
  [actions.POST_VESSEL_SCHEDULE]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        // postVesselScheduleAction: payload,
        postVesselScheduleResponse: payload,
      },
    }
  },
  [actions.GET_SCHEDULE_PLANNER_LIST]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        schedulePlanner: {
          ...state.optimisePage.schedulePlanner,
          listData: payload,
        },
      },
    }
  },
  [actions.GET_VESSEL_SCHEDULE_CHART]: (state, { payload }) => {
    const data = getPayloadVoyageChart(payload);
    const sameDayVoyages = getSameDayVoyages(data);
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        vesselSchedule: {
          ...state.optimisePage.vesselSchedule,
          data,
          sameDayVoyages
        },
      },
    }
  },
  [actions.GET_VESSEL_SCHEDULE_FOOTER]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        vesselSchedule: {
          ...state.optimisePage.vesselSchedule,
          vesselScheduleFooter: payload,
        },
      },
    }
  },
  [actions.GET_VESSEL_SCHEDULE_SIDEBAR]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        vesselSchedule: {
          ...state.optimisePage.vesselSchedule,
          sideBar: payload,
        },
      },
    }
  },
  [actions.SET_SCHEDULE_PLANNER_INFO]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        schedulePlanner: {
          ...state.optimisePage.schedulePlanner,
          regionCode: payload.regionCode,
          regionType: payload.regionType,
          period: {
            startDate: payload.period[0]?.startDate ?? '',
            endDate: payload.period[0]?.endDate ?? '',
          },
        },
      },
    }
  },
  [actions.GET_START_VESSEL]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        bookVessel: {
          ...state.optimisePage.bookVessel,
          startVesselList: _.get(payload, 'vessels'),
        },
      },
    }
  },
  [actions.GET_VESSEL_BY_REGION]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        vesselByRegionDropdown: _.get(payload, 'data')
      },
    }
  },
  [actions.GET_START_LOCATION]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        bookVessel: {
          ...state.optimisePage.bookVessel,
          locationList: _.get(payload, 'locations'),
        },
      },
    }
  },

  [actions.GET_SUPPLY_BASE_LOCATION]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        supplyBaseLocationList: _.get(payload, 'locations')
      },
    }
  },

  [actions.GET_UPLOAD_DATA_FILES]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        uploadData: {
          ...state.optimisePage.uploadData,
          fileDetail: payload,
        },
      },
    }
  },
  [actions.GET_UPLOAD_DATA_FILES_LIST]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        uploadData: {
          ...state.optimisePage.uploadData,
          fileList: payload,
        },
      },
    }
  },
  [actions.POST_UPLOAD_DATA_FILES]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        uploadData: {
          ...state.optimisePage.uploadData,
          uploadStatus: payload,
        },
      },
    }
  },
  [actions.SET_CVS_PAGE_STATE]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        createNewSchedStatus: payload,
      },
    }
  },
  [actions.SET_DECLINE_PUBLISH_DUMMY_STATUS]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        declinePublishDummy: payload,
      },
    }
  },
  [actions.SET_NEW_CUSTOM_CVS_SAVED_STATUS]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        saveNewCustomCVS: payload,
      },
    }
  },
  [actions.SET_OPENED_TABS]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        openedTabs: payload,
      },
    }
  },
  [actions.GET_DATE_RANGE_BY_REGION_CODE]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        dateRangeByRegionCode: payload.date_range,
      },
    }
  },
  [actions.GET_VESSEL_JOB_TYPE_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        vesselJobType: payload.vessel_job_type,
      },
    }
  },
  [actions.GET_BUSINESS_UNIT_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        businessUnit: payload.business_unit,
      },
    }
  },
  [actions.GET_VESSEL_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        vesselDropdownList: payload.vessels,
      },
    }
  },
  [actions.GET_SUPPLY_BASE_BY_REGION_CODE]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        supplyBaseByRegionCode: payload.locations,
      },
    }
  },
  [actions.GET_ROVING_SERVICE_TYPE]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        rovingServiceType: payload.service_types,
      },
    }
  },
  [actions.SET_VESSEL_SCHEDULE_INFO]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        vesselSchedule: {
          ...state.optimisePage.vesselSchedule,
          regionCode: payload.regionCode,
          period: {
            startDate: payload.period[0]?.startDate ?? '',
            endDate: payload.period[0]?.endDate ?? '',
          },
          fromDraft: payload.fromDraft,
        },
      },
    }
  },
  [actions.GET_UNDERDECK_CARGO_DROPDOWN]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        underDeckCargoDropdown: _.get(payload, 'cargo')
      },
    }
  },
  [actions.GET_VESSEL_CAPACITY]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        vesselCapacity: _.get(payload, 'vessel_capacity'),
      },
    }
  },
  [actions.GET_VESSEL_CONTRACT_PERIOD]: (state, { payload }) => {
    const vesselDisabledDates = getDisabledDatesByContract({contractList: payload?.contractList, vesselContractPeriod: payload?.dateRange }) || []
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        vesselContractPeriod: payload || {},
        vesselDisabledDates,
      },
    }
  },
  [actions.POST_VESSEL_SCHEDULE_SIDEBAR]: (state, { payload }) => {    
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        addVoyageStatus: {
          isValid: payload.is_valid,
          isPLTIntegration: payload?.is_plt_integration,
          message: payload.message,
          message_detail: payload?.message_detail,
        },
        vesselScheduleToastMessage: {
          message: vesselScheduleStatus(payload, 'created', state.optimisePage.isPLTIntegration),
          isDisplayed: true
        }
      },
    }
  },
  [actions.POST_CUSTOM_SCENARIO_MODELLING]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        saveCustomScenarioStatus: payload.message
      }
    }
  },
  [actions.PUT_VESSEL_SCHEDULE_SIDEBAR]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        addVoyageStatus: {
          isValid: payload.is_valid,
          isPLTIntegration: payload?.is_plt_integration,
          message: payload.message,
          message_detail: payload?.message_detail,
        },
        vesselScheduleToastMessage: {
          message: vesselScheduleStatus(payload, 'updated', state.optimisePage.isPLTIntegration),
          isDisplayed: true
        }
      },
    }
  }, [actions.PUT_SCENARIO_MODELLING]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        saveScenarioStatus: payload,
      },
    }
  },
  [actions.RESET_ADD_DEL_VOYAGE_STATUS]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        addVoyageStatus: {
          isValid: false,
          message: '',
        },
        deleteVoyageStatus: {
          isValid: false,
          message: '',
        },
      },
    }
  },
  [actions.RESET_CUSTOM_SCENARIO_MODELLING_STATUS]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        saveCustomScenarioStatus: '',
      },
    }
  },
  [actions.RESET_CVS_SIDEBAR_DATA]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        schedulePlanner: {
          ...state.optimisePage.schedulePlanner,
          chartDetails: [],
        },
      },
    }
  },
  [actions.GET_SCHEDULE_COMPARISON]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        scheduleComparison: _.get(payload, 'chart_data'),
        scheduleMonth: _.get(payload, 'months')
      },
    }
  },
  [actions.PUT_CONFIRM_SCHEDULE]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        publishVesselSuccess: _.get(payload, 'is_valid'),
        publishVesselMessage: _.get(payload, 'message'),
        publishVesselIsPLTIntegration: _.get(payload, 'is_plt_integration'),
        publishVesselMessageDetail: payload,
      },
    }
  },
  [actions.RESET_CONFIRM_SCHEDULE_STATUS]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        publishVesselSuccess: payload,
        publishVesselMessage: payload,
      },
    }
  },
  [actions.SET_NEW_VOYAGE_DETAILS]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        newVoyageDetails: {
          jobType: payload.jobType,
          vesselName: payload.vesselName,
          vesselId: payload.vesselId,
        },
      },
    }
  },
  [actions.DELETE_PUBLISHED_VOYAGE]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        deleteVoyageStatus: {
          isValid: payload.is_valid,
          isPLTIntegration: payload?.is_plt_integration,
          message: payload.message,
          message_detail: payload?.message_detail
        },
        vesselScheduleToastMessage: {
          message: vesselScheduleStatus(payload, 'deleted', state.optimisePage.isPLTIntegration),
          isDisplayed: true
        }
      },
    }
  },
  [actions.RESET_VESSEL_SCHEDULE_SIDEBAR]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        vesselSchedule: {
          ...state.optimisePage.vesselSchedule,
          sideBar: payload,
        },
      },
    }
  },
  [actions.GET_VOYAGE_SCHEDULE_SEQ_PRIORITY]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        editVoyageScheduleSeqPriority: payload?.data?.vessel_seq_priority,
        berthing_time: payload?.data?.berthing_time,
        berthing_time_minute: payload?.data?.berthing_time_minute,
      },
    }
  },
  [actions.POST_VOYAGE_SCHEDULE_SEQ_PRIORITY]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        voyageScheduleSeqPriorityStatus: payload
      },
    }
  },

  [actions.PUT_VOYAGE_SCHEDULE_SEQ_PRIORITY]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        voyageScheduleSeqPriorityStatus: payload
      },
    }
  },

  [actions.RESET_VOYAGE_SCHEDULE_SEQ_PRIORITY_STATUS]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        voyageScheduleSeqPriorityStatus: {}
      },
    }
  },
  [actions.GET_SCHEDULE_STATUS_D3_BY_ID]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        vesselSchedule: {
          ...state.optimisePage.vesselSchedule,
          sideBar: {
            ...state.optimisePage.vesselSchedule.sideBar,
            scheduleStatusD3: payload
          },
        },
      },

    }
  },
  [actions.PUT_SCHEDULE_STATUS_D3]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        vesselSchedule: {
          ...state.optimisePage.vesselSchedule,
          putScheduleStatusD3: payload 
        },
      },

    }
  },
  [actions.RESET_SCHEDULE_STATUS_D3]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        vesselSchedule: {
          ...state.optimisePage.vesselSchedule,
          sideBar: {
            ...state.optimisePage.vesselSchedule.sideBar,
            scheduleStatusD3: {}
          },
        },
      },
    }
  },

  [actions.GET_DOWNLOAD_SCHEDULE_D_MINUS_3]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        download_schedule_d3: {
          filteredD3Schedules: payload?.data?.filteredD3Schedules,
          completeD3Schedules: payload?.data?.completeD3Schedules,
        },
      },
    }
  },

  [actions.RESET_DOWNLOAD_SCHEDULE_D_MINUS_3]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        download_schedule_d3: null,
      },
    }
  },
  [actions.PUT_SCHEDULE_MAINTENANCE]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        maintenanceEditStatus: payload,
      },
    }
  },
  [actions.RESET_MAINTENANCE_EDIT_STATUS]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        maintenanceEditStatus: {
          is_valid: false,
          message: ''
        },
      },
    }
  },
  [actions.DELETE_SCENARIO_MODELLING]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        scenarioModellingDeleteStatus: `${payload}`
      },
    }
  },
  [actions.RESET_SCENARIO_MODELLING_DEL_STATUS]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        scenarioModellingDeleteStatus: ''
      },
    }
  }, [actions.POST_BOOK_MARINE_SERVICE]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        bookMarineServiceStatus: payload
      },
    }
  }, [actions.RESET_BOOK_MARINE_STATUS]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        bookMarineServiceStatus: {
          is_valid: true,
          message: '',
          scenario_id: '',
        }
      },
    }
  }, [actions.RESET_POST_VESSEL_SCHEDULE_STATUS]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        postVesselScheduleResponse: {},
      },
    }
  }, [actions.SET_STATE_OPTIMISE_PAGE]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        ...payload
      },
    }
  }, [actions.POST_EXECUTE_D_MINUS_3]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        vesselSchedule: {
          ...state.optimisePage.vesselSchedule,
          sideBar: {
            ...state.optimisePage.vesselSchedule.sideBar,
            executeD3OptimiseStatus: payload
          },
        },
      },
    }
  }, [actions.POST_PLT_INTEGRATION]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        responseAfterRetryPublishPLT: payload,
        addVoyageStatus: {
          isValid: payload.is_valid,
          isPLTIntegration: payload?.is_plt_integration,
          message: payload.message,
          message_detail: payload?.message_detail
        }      
      },
    }
  }, [actions.POST_PLT_INTEGRATION_D3_OPTIMISE]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        schedulePLTStatusD3: payload,
        vesselScheduleToastMessage: {
          message: payload.message,
          isDisplayed: payload.is_valid
        }
      },
    }
  }, [actions.PUT_PLT_INTEGRATION_CONFIRM_SCHEDULE]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        publishVesselSuccess: _.get(payload, 'is_valid'),
        publishVesselMessage: _.get(payload, 'message'),
        publishVesselIsPLTIntegration: _.get(payload, 'is_plt_integration'),
        publishVesselMessageDetail: payload,
      },
    }
  }, [actions.SET_VESSEL_SELECTED]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        bookVessel: {
          ...state.optimisePage.bookVessel,
          vesselSelected: payload
        },
      },
    }
  }, [actions.POST_DEMAND_FORCE_CAST]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        demandForceCast: payload
      },
    }
  },
  [actions.COMPARE_PLT_SCHEDULE_SHOW_DETAIL]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        compareScheduleData: payload,
      },
    }
  },
  [actions.POST_VOYAGE_ACTIVITY]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        voyageActivity: payload,
        vesselScheduleToastMessage: {
          message: payload.message,
          isDisplayed: payload.is_valid
        },
      },
    }
  },
  [actions.POST_FETCH_DEMAND_DATA]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        vesselSchedule: {
          ...state.optimisePage.vesselSchedule,
          fetchDemandData: payload,
        },
      },
    }
  },
  [actions.POST_VESSEL_SCHEDULE_WEATHER_DATA]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        vesselScheduleWeatherData: payload
      },
    }
  },
  [actions.GET_ACTIVITY_LOGS]: (state, { payload }) => {
    return {
      ...state,
      optimisePage: {
        ...state.optimisePage,
        activityLogs: payload?.activityLogs
      }
    }
  }
}
// eslint-disable-next-line
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error })
  }
  return state
}

export const OPTIMISE_PAGE_REDUCER = 'optimisePage'

import React from "react";
import ReactDOM from "react-dom";
import WebFont from "webfontloader";
import AuthService from "./AuthService";
import { MsalProvider } from '@azure/msal-react';

//library css
import "semantic-ui-css/semantic.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'ag-grid-enterprise';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
//application css
import "./index.scss";
import App from "./app";

const auth = new AuthService();

ReactDOM.render(<MsalProvider instance={await auth.getInstance()}><App auth={auth} /></MsalProvider>, document.getElementById("root"))

WebFont.load({
  google: {
    families: ["IBM Plex Sans"],
  },
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

import _ from 'lodash'

import * as services from '../services/serviceOptimise'

import { fetchError, fetchStart, fetchSuccess } from './common/actionFetch'
import { TYPE_OF_PLT_INTEGRATION } from '../constants/optimise'

export const RESET_OPTIMISE_PAGE = 'RESET_OPTIMISE_PAGE'
export const resetOptimisePage = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_OPTIMISE_PAGE,
      payload: [],
    })
  }
}

export const GET_VESSEL_SCHEDULE = 'GET_VESSEL_SCHEDULE'
export const getVesselSchedule = (regionCode, period) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VESSEL_SCHEDULE))
    services
      .getVesselSchedule(regionCode, period)
      .then((response) =>
        dispatch({
          type: GET_VESSEL_SCHEDULE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_VESSEL_SCHEDULE)))
      .catch(() => dispatch(fetchError(GET_VESSEL_SCHEDULE)))
  }
}

export const getVesselScheduleByRegionPeriodSupplyBase = (regionCode, supplyBase, period) => {  // Get Vessel Schedule by Region Code and Period and Supply Base
  return (dispatch) => {
    dispatch(fetchStart(GET_VESSEL_SCHEDULE))
    services
      .getVesselScheduleByRegionPeriodSupplyBaseAPI(regionCode, supplyBase, period)
      .then((response) =>
        dispatch({
          type: GET_VESSEL_SCHEDULE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_VESSEL_SCHEDULE)))
      .catch(() => dispatch(fetchError(GET_VESSEL_SCHEDULE)))
  }
}

export const POST_VESSEL_SCHEDULE = 'POST_VESSEL_SCHEDULE'
export const postVesselSchedule = (regionCode, period, jobType, supplyBase = null) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VESSEL_SCHEDULE))
    services
      .postVesselSchedule(regionCode, period, jobType, supplyBase)
      .then((response) =>
        dispatch({
          type: POST_VESSEL_SCHEDULE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VESSEL_SCHEDULE)))
      .catch(() => dispatch(fetchError(POST_VESSEL_SCHEDULE)))
  }
}

export const COMPARE_PLT_SCHEDULE = "COMPARE_PLT_SCHEDULE";
export const comparePLTSchedule = (voyageID) => {
  return (dispatch) => {
    dispatch(fetchStart(COMPARE_PLT_SCHEDULE));
    return services
      .comparePLTScheduleAPI(voyageID)
      .then((response) => {
        dispatch(fetchSuccess(COMPARE_PLT_SCHEDULE));
        return response;
      })
      .catch(() => dispatch(fetchError(COMPARE_PLT_SCHEDULE)));
  };
};

export const COMPARE_PLT_SCHEDULE_SHOW_DETAIL = "COMPARE_PLT_SCHEDULE_SHOW_DETAIL";
export const comparePLTScheduleShowDetail = (voyageID) => {
  return (dispatch) => {
    dispatch(fetchStart(COMPARE_PLT_SCHEDULE_SHOW_DETAIL));
    services
      .comparePLTScheduleShowDetailAPI(voyageID)
      .then((response) =>
        dispatch({
          type: COMPARE_PLT_SCHEDULE_SHOW_DETAIL,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(COMPARE_PLT_SCHEDULE_SHOW_DETAIL)))
      .catch(() => dispatch(fetchError(COMPARE_PLT_SCHEDULE_SHOW_DETAIL)))
  }
};

export const GET_SCENARIO_MODELLING = 'GET_SCENARIO_MODELLING'
export const getScenarioModelling = (regionCode, period, jobType, supplyBase = null) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_SCENARIO_MODELLING))
    services
      .getScenarioModelling(regionCode, period, jobType, supplyBase)
      .then((response) =>
        dispatch({
          type: GET_SCENARIO_MODELLING,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_SCENARIO_MODELLING)))
      .catch(() => dispatch(fetchError(GET_SCENARIO_MODELLING)))
  }
}

export const PUT_SCENARIO_MODELLING = 'PUT_SCENARIO_MODELLING'
export const putScenarioModelling = (regionCode, period, jobType, scenarioType, supplyBase = null) => {
  return (dispatch) => {
    dispatch(fetchStart(PUT_SCENARIO_MODELLING))
    services
      .putScenarioModelling(regionCode, period, jobType, scenarioType, supplyBase)
      .then((response) =>
        dispatch({
          type: PUT_SCENARIO_MODELLING,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(PUT_SCENARIO_MODELLING)))
      .catch(() => dispatch(fetchError(PUT_SCENARIO_MODELLING)))
  }
}

export const DELETE_SCENARIO_MODELLING = 'DELETE_SCENARIO_MODELLING'
export const deleteScenarioModelling = (regionCode, period, jobType, supplyBase = null) => {
  return (dispatch) => {
    dispatch(fetchStart(DELETE_SCENARIO_MODELLING))
    services
      .deleteScenarioModelling(regionCode, period, jobType, supplyBase)
      .then((response) =>
        dispatch({
          type: DELETE_SCENARIO_MODELLING,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(DELETE_SCENARIO_MODELLING)))
      .catch(() => dispatch(fetchError(DELETE_SCENARIO_MODELLING)))
  }
}

export const GET_USER_REGION_DROPDOWN = 'GET_USER_REGION_DROPDOWN'
export const getUserRegionDropdown = (feature='') => {
  return (dispatch) => {
    dispatch(fetchStart(GET_USER_REGION_DROPDOWN))
    services
      .getUserRegionDropdown(feature)
      .then((response) =>
        dispatch({
          type: GET_USER_REGION_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_USER_REGION_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_USER_REGION_DROPDOWN)))
  }
}

export const GET_SCHEDULE_PLANNER_CHART = 'GET_SCHEDULE_PLANNER_CHART'
export const getSchedulePlannerChart = (
  materialRegionCode,
  peopleRegionCode,
  marineRegionCode,
  // period,
  startDate,
  endDate,
  supplyBase = null
) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_SCHEDULE_PLANNER_CHART))
    services
      .getSchedulePlannerChart(
        materialRegionCode,
        peopleRegionCode,
        marineRegionCode,
        startDate,
        endDate,
        supplyBase
      )
      .then((response) =>
        dispatch({
          type: GET_SCHEDULE_PLANNER_CHART,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_SCHEDULE_PLANNER_CHART)))
      .catch(() => dispatch(fetchError(GET_SCHEDULE_PLANNER_CHART)))
  }
}

export const GET_CONFIRM_SCHEDULE = 'GET_CONFIRM_SCHEDULE'
export const getConfirmSchedule = (
  materialRegionCode,
  peopleRegionCode,
  marineRegionCode,
  // period
  startDate,
  endDate,
  supplyBase = null
) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_CONFIRM_SCHEDULE))
    services
      .getConfirmSchedule(materialRegionCode, peopleRegionCode, marineRegionCode, startDate, endDate, supplyBase)
      .then((response) =>
        dispatch({
          type: GET_CONFIRM_SCHEDULE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_CONFIRM_SCHEDULE)))
      .catch(() => dispatch(fetchError(GET_CONFIRM_SCHEDULE)))
  }
}


export const GET_SCHEDULE_COMPARISON = 'GET_SCHEDULE_COMPARISON'
export const getScheduleComparison = (kpiType, regionCode, startDate, endDate) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_SCHEDULE_COMPARISON))
    services
      .getScheduleComparison(kpiType, regionCode, startDate, endDate)
      .then((response) => {
        dispatch({
          type: GET_SCHEDULE_COMPARISON,
          payload: response,
        })
      })
      .then(() => dispatch(fetchSuccess(GET_SCHEDULE_COMPARISON)))
      .catch(() => dispatch(fetchError(GET_SCHEDULE_COMPARISON)))
  }
}

export const GET_SUPPLY_BASE_LOCATION = 'GET_SUPPLY_BASE_LOCATION'
export const getSupplyBaseLocationDropdown = (additionalParam) => {
  const extraParam = _.map(additionalParam, (item) => `&${item.properties}=${item.value}`)
  return (dispatch) => {
    dispatch(fetchStart(GET_SUPPLY_BASE_LOCATION))
    services
      .getDropdown('supply_base', extraParam)
      .then((response) =>
        dispatch({
          type: GET_SUPPLY_BASE_LOCATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_SUPPLY_BASE_LOCATION)))
      .catch(() => dispatch(fetchError(GET_SUPPLY_BASE_LOCATION)))
  }
}

export const GET_VESSEL_DROPDOWN = 'GET_VESSEL_DROPDOWN'
export const getVesselDropdown = (additionalParam) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VESSEL_DROPDOWN))
    services
      .getDropdownD30OptionalVessels('D30_optional_vessels', additionalParam)
      .then((response) =>
        dispatch({
          type: GET_VESSEL_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_VESSEL_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_VESSEL_DROPDOWN)))
  }
}

export const PUT_CONFIRM_SCHEDULE = 'PUT_CONFIRM_SCHEDULE'
export const putConfirmSchedule = (
  startDate,
  endDate,
  regionCode,
  supplyBase = null
) => {
  return (dispatch) => {
    dispatch(fetchStart(PUT_CONFIRM_SCHEDULE))
    services
      .putConfirmSchedule(startDate, endDate, regionCode, supplyBase)
      .then((response) =>
        dispatch({
          type: PUT_CONFIRM_SCHEDULE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(PUT_CONFIRM_SCHEDULE)))
      .catch(() => dispatch(fetchError(PUT_CONFIRM_SCHEDULE)))
  }
}

export const GET_SCHEDULE_PLANNER_CHART_DETAILS = 'GET_SCHEDULE_PLANNER_CHART_DETAILS'
export const getSchedulePlannerChartDetail = (vesselId, voyageId, jobType, scheduleHdrId = '') => {
  return (dispatch) => {
    dispatch(fetchStart(GET_SCHEDULE_PLANNER_CHART_DETAILS))
    services
      .getSchedulePlannerChartDetail(vesselId, voyageId, jobType, scheduleHdrId)
      .then((response) =>
        dispatch({
          type: GET_SCHEDULE_PLANNER_CHART_DETAILS,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_SCHEDULE_PLANNER_CHART_DETAILS)))
      .catch(() => dispatch(fetchError(GET_SCHEDULE_PLANNER_CHART_DETAILS)))
  }
}

export const GET_SCHEDULE_OVERVIEW_FOOTER = 'GET_SCHEDULE_OVERVIEW_FOOTER'
export const getScheduleOverviewFooter = (
  materialRegionCode,
  peopleRegionCode,
  marineRegionCode,
  // period
  startDate,
  endDate,
  isPublished = 0,
  supplyBase = null,
) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_SCHEDULE_OVERVIEW_FOOTER))
    services
      .getScheduleOverviewFooter(
        materialRegionCode,
        peopleRegionCode,
        marineRegionCode,
        startDate,
        endDate,
        isPublished,
        supplyBase
      )
      .then((response) =>
        dispatch({
          type: GET_SCHEDULE_OVERVIEW_FOOTER,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_SCHEDULE_OVERVIEW_FOOTER)))
      .catch(() => dispatch(fetchError(GET_SCHEDULE_OVERVIEW_FOOTER)))
  }
}

export const POST_EXECUTE_MODEL = 'POST_EXECUTE_MODEL'
export const postExecuteModel = (regionCode, period, jobType, scenarioId, supplyBase = null, business_units, vessel_job_types, vessels) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_EXECUTE_MODEL))
    services
      .postExecuteModel(regionCode, period, jobType, scenarioId, supplyBase, business_units, vessel_job_types, vessels)
      .then((response) =>
        dispatch({
          type: POST_EXECUTE_MODEL,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_EXECUTE_MODEL)))
      .catch(() => dispatch(fetchError(POST_EXECUTE_MODEL)))
  }
}

export const GET_SCHEDULE_PLANNER_LIST = 'GET_SCHEDULE_PLANNER_LIST'
export const getSchedulePlannerList = (
  // period,
  startDate,
  endDate,
  materialRegionCode,
  peopleRegionCode,
  marineRegionCode
) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_SCHEDULE_PLANNER_LIST))
    services
      .getSchedulePlannerList(
        startDate,
        endDate,
        materialRegionCode,
        peopleRegionCode,
        marineRegionCode
      )
      .then((response) =>
        dispatch({
          type: GET_SCHEDULE_PLANNER_LIST,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_SCHEDULE_PLANNER_LIST)))
      .catch(() => dispatch(fetchError(GET_SCHEDULE_PLANNER_LIST)))
  }
}

export const GET_VESSEL_SCHEDULE_CHART = 'GET_VESSEL_SCHEDULE_CHART'
export const getVesselScheduleChart = (
  materialRegionCode,
  peopleRegionCode,
  marineRegionCode,
  startDate,
  endDate,  
  vesselId = null,
) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VESSEL_SCHEDULE_CHART))
    services
      .getVesselScheduleChart(
        materialRegionCode,
        peopleRegionCode,
        marineRegionCode,
        startDate,
        endDate,
        JSON.stringify(vesselId?.some(item => item === 'all') || _.isEmpty(vesselId) ? null : vesselId)
      )
      .then((response) =>
        dispatch({
          type: GET_VESSEL_SCHEDULE_CHART,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_VESSEL_SCHEDULE_CHART)))
      .catch(() => dispatch(fetchError(GET_VESSEL_SCHEDULE_CHART)))
  }
}

export const GET_VESSEL_SCHEDULE_SIDEBAR = 'GET_VESSEL_SCHEDULE_SIDEBAR'
export const getVesselScheduleSidebar = (voyageId, scheduleHdrId = null, isImperial = false) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VESSEL_SCHEDULE_SIDEBAR))
    services
      .getVesselScheduleSidebar(voyageId, scheduleHdrId, isImperial)
      .then((response) =>
        dispatch({
          type: GET_VESSEL_SCHEDULE_SIDEBAR,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_VESSEL_SCHEDULE_SIDEBAR)))
      .catch(() => dispatch(fetchError(GET_VESSEL_SCHEDULE_SIDEBAR)))
  }
}

export const GET_VESSEL_SCHEDULE_FOOTER = 'GET_VESSEL_SCHEDULE_FOOTER'
export const getVesselScheduleFooter = (
  materialRegionCode,
  peopleRegionCode,
  marineRegionCode,
  period
) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VESSEL_SCHEDULE_FOOTER))
    services
      .getVesselScheduleFooter(materialRegionCode, peopleRegionCode, marineRegionCode, period)
      .then((response) =>
        dispatch({
          type: GET_VESSEL_SCHEDULE_FOOTER,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_VESSEL_SCHEDULE_FOOTER)))
      .catch(() => dispatch(fetchError(GET_VESSEL_SCHEDULE_FOOTER)))
  }
}

export const SET_SCHEDULE_PLANNER_INFO = 'SET_SCHEDULE_PLANNER_INFO'
export const setSchedulePlannerInfo = (info) => {
  return {
    type: SET_SCHEDULE_PLANNER_INFO,
    payload: info,
  }
}

export const PUT_SCHEDULE_MAINTENANCE = 'PUT_SCHEDULE_MAINTENANCE'
export const putScheduleMaintenance = (maintenanceList) => {
  return (dispatch) => {
    dispatch(fetchStart(PUT_SCHEDULE_MAINTENANCE))
    services
      .putScheduleMaintenance(maintenanceList)
      .then((response) =>
        dispatch({
          type: PUT_SCHEDULE_MAINTENANCE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(PUT_SCHEDULE_MAINTENANCE)))
      .catch(() => dispatch(fetchError(PUT_SCHEDULE_MAINTENANCE)))
  }
}

export const GET_START_VESSEL = 'GET_START_VESSEL'
export const getStartVessel = (additionalParam, isVesselDummy = false) => {
  const extraParam = _.map(additionalParam, (item) => `&${item.properties}=${item.value}`)
  const dropdownType = isVesselDummy ? 'vessel_dummy' : 'vessel'
  return (dispatch) => {
    dispatch(fetchStart(GET_START_VESSEL))
    services
      .getDropdown(dropdownType, extraParam)
      .then((response) =>
        dispatch({
          type: GET_START_VESSEL,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_START_VESSEL)))
      .catch(() => dispatch(fetchError(GET_START_VESSEL)))
  }
}

export const GET_VESSEL_BY_REGION = 'GET_VESSEL_BY_REGION'
export const getVesselByRegion = (additionalParam, isVesselDummy = false) => {
  const extraParam = _.map(additionalParam, (item) => `&${item.properties}=${item.value}`)

  return (dispatch) => {
    dispatch(fetchStart(GET_VESSEL_BY_REGION))
    services
      .getDropdown("vessel_by_region", extraParam)
      .then((response) =>
        dispatch({
          type: GET_VESSEL_BY_REGION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_VESSEL_BY_REGION)))
      .catch(() => dispatch(fetchError(GET_VESSEL_BY_REGION)))
  }
}

export const GET_START_LOCATION = 'GET_START_LOCATION'
export const getStartLocation = (additionalParam) => {
  const extraParam = _.map(additionalParam, (item) => `&${item.properties}=${item.value}`)
  return (dispatch) => {
    dispatch(fetchStart(GET_START_LOCATION))
    services
      .getDropdown('location', extraParam)
      .then((response) =>
        dispatch({
          type: GET_START_LOCATION,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_START_LOCATION)))
      .catch(() => dispatch(fetchError(GET_START_LOCATION)))
  }
}

export const POST_BOOK_VESSEL_SCHEDULE = 'POST_BOOK_VESSEL_SCHEDULE'
export const postBookVesselSchedule = (
  region,
  startDate,
  endDate,
  marineStartDate,
  locationName,
  vesselId,
  loadRequirement,
  jobType
) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_BOOK_VESSEL_SCHEDULE))
    services
      .postBookVesselSchedule(
        region,
        startDate,
        endDate,
        marineStartDate,
        locationName,
        vesselId,
        loadRequirement,
        jobType
      )
      .then((response) =>
        dispatch({
          type: POST_BOOK_VESSEL_SCHEDULE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_BOOK_VESSEL_SCHEDULE)))
      .catch(() => dispatch(fetchError(POST_BOOK_VESSEL_SCHEDULE)))
  }
}
export const POST_UPLOAD_DATA_FILES = 'POST_UPLOAD_DATA_FILES'
export const postUploadDataFiles = (uploadFile, filterCategory, filterStartDate, filterEndDate) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_UPLOAD_DATA_FILES))
    services
      .postUploadDataFiles(uploadFile)
      .then((response) => {
        dispatch({
          type: POST_UPLOAD_DATA_FILES,
          payload: response,
        })

        services
          .getUploadDataFilesList(filterCategory, filterStartDate, filterEndDate)
          .then((response) =>
            dispatch({
              type: GET_UPLOAD_DATA_FILES_LIST,
              payload: response,
            })
          )
          .then(() => dispatch(fetchSuccess(GET_UPLOAD_DATA_FILES_LIST)))
          .catch(() => dispatch(fetchError(GET_UPLOAD_DATA_FILES_LIST)))
      })
      .then(() => dispatch(fetchSuccess(POST_UPLOAD_DATA_FILES)))
      .catch(() => dispatch(fetchError(POST_UPLOAD_DATA_FILES)))
  }
}

export const POST_BOOK_MARINE_SERVICE = 'POST_BOOK_MARINE_SERVICE'
export const postBookMarineService = (inputParams = {}) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_BOOK_MARINE_SERVICE))
    services
      .postBookMarineService(inputParams)
      .then((response) =>
        dispatch({
          type: POST_BOOK_MARINE_SERVICE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_BOOK_MARINE_SERVICE)))
      .catch(() => dispatch(fetchError(POST_BOOK_MARINE_SERVICE)))
  }
}

export const POST_OPTIMISE_BOOK_VESSEL_SCHEDULE = 'POST_OPTIMISE_BOOK_VESSEL_SCHEDULE'
export const postOptimiseBookVesselSchedule = (
  region,
  startDate,
  endDate,
  marineStartDate,
  marineEndDate,
  locationName,
  vesselId,
  loadRequirement,
  marineType,
  jobType
) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_OPTIMISE_BOOK_VESSEL_SCHEDULE))
    services
      .postOptimiseBookVesselSchedule(
        region,
        startDate,
        endDate,
        marineStartDate,
        marineEndDate,
        locationName,
        vesselId,
        loadRequirement,
        marineType,
        jobType
      )
      .then((response) =>
        dispatch({
          type: POST_OPTIMISE_BOOK_VESSEL_SCHEDULE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_OPTIMISE_BOOK_VESSEL_SCHEDULE)))
      .catch(() => dispatch(fetchError(POST_OPTIMISE_BOOK_VESSEL_SCHEDULE)))
  }
}

export const POST_OPTIMISE_BOOK_ROVING_SCHEDULE = 'POST_OPTIMISE_BOOK_ROVING_SCHEDULE'
export const postOptimiseBookRovingSchedule = (
  region,
  startDate,
  endDate,
  marineStartDate,
  marineEndDate,
  locationName,
  vesselId,
  serviceTypeId,
  marineType,
  jobType
) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_OPTIMISE_BOOK_ROVING_SCHEDULE))
    services
      .postOptimiseBookRovingSchedule(
        region,
        startDate,
        endDate,
        marineStartDate,
        marineEndDate,
        locationName,
        vesselId,
        serviceTypeId,
        marineType,
        jobType
      )
      .then((response) =>
        dispatch({
          type: POST_OPTIMISE_BOOK_ROVING_SCHEDULE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_OPTIMISE_BOOK_ROVING_SCHEDULE)))
      .catch(() => dispatch(fetchError(POST_OPTIMISE_BOOK_ROVING_SCHEDULE)))
  }
}

export const GET_UPLOAD_DATA_FILES = 'GET_UPLOAD_DATA_FILES'
export const getUploadDataFiles = (category, fileName) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_UPLOAD_DATA_FILES))
    services
      .getUploadDataFiles(category, fileName)
      .then((response) =>
        dispatch({
          type: GET_UPLOAD_DATA_FILES,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_UPLOAD_DATA_FILES)))
      .catch(() => dispatch(fetchError(GET_UPLOAD_DATA_FILES)))
  }
}

export const GET_UPLOAD_DATA_FILES_LIST = 'GET_UPLOAD_DATA_FILES_LIST'
export const getUploadDataFilesList = (filterCategory, filterStartDate, filterEndDate) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_UPLOAD_DATA_FILES_LIST))
    services
      .getUploadDataFilesList(filterCategory, filterStartDate, filterEndDate)
      .then((response) =>
        dispatch({
          type: GET_UPLOAD_DATA_FILES_LIST,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_UPLOAD_DATA_FILES_LIST)))
      .catch(() => dispatch(fetchError(GET_UPLOAD_DATA_FILES_LIST)))
  }
}

export const GET_TEMPLATE_BINARY_DATA = 'GET_TEMPLATE_BINARY_DATA'
export const getTemplateBinaryData = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_TEMPLATE_BINARY_DATA))
    return services
      .getTemplateBinaryData()
      .then((response) => {
        dispatch(fetchSuccess(GET_TEMPLATE_BINARY_DATA))
        return response
      })
      .catch(() => dispatch(fetchError(GET_TEMPLATE_BINARY_DATA)))
  }
}

export const SET_OPENED_TABS = 'SET_OPENED_TABS'
export const setOpenedTabs = (tabList) => {
  return {
    type: SET_OPENED_TABS,
    payload: tabList,
  }
}

export const SET_DECLINE_PUBLISH_DUMMY_STATUS = 'SET_DECLINE_PUBLISH_DUMMY_STATUS'
export const setDeclinePublishDummyStatus = (state) => {
  return {
    type: SET_DECLINE_PUBLISH_DUMMY_STATUS,
    payload: state,
  }
}

export const SET_NEW_CUSTOM_CVS_SAVED_STATUS = 'SET_NEW_CUSTOM_CVS_SAVED_STATUS'
export const setNewCustomCVSSavedStatus = (state) => {
  return {
    type: SET_NEW_CUSTOM_CVS_SAVED_STATUS,
    payload: state,
  }
}

export const SET_CVS_PAGE_STATE = 'SET_CVS_PAGE_STATE'
export const setCvsPageState = (state) => {
  return {
    type: SET_CVS_PAGE_STATE,
    payload: state,
  }
}

export const GET_SUPPLY_BASE_BY_REGION_CODE = 'GET_SUPPLY_BASE_BY_REGION_CODE'
export const getSupplyBaseByRegionCode = (additionalParam) => {
  const extraParam = _.map(additionalParam, (item) => `&${item.properties}=${item.value}`)
  return (dispatch) => {
    dispatch(fetchStart(GET_SUPPLY_BASE_BY_REGION_CODE))
    services
      .getDropdown('supply_base', extraParam)
      .then((response) =>
        dispatch({
          type: GET_SUPPLY_BASE_BY_REGION_CODE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_SUPPLY_BASE_BY_REGION_CODE)))
      .catch(() => dispatch(fetchError(GET_SUPPLY_BASE_BY_REGION_CODE)))
  }
}

export const GET_BUSINESS_UNIT_DROPDOWN = 'GET_BUSINESS_UNIT_DROPDOWN'
export const getBusinessUnitDropdown = (additionalParam) => {
  const extraParam = _.map(additionalParam, (item) => `&${item.properties}=${item.value}`)
  return (dispatch) => {
    dispatch(fetchStart(GET_BUSINESS_UNIT_DROPDOWN))
    services
      .getDropdown('business_unit', extraParam)
      .then((response) =>
        dispatch({
          type: GET_BUSINESS_UNIT_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_BUSINESS_UNIT_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_BUSINESS_UNIT_DROPDOWN)))
  }
}

export const GET_VESSEL_JOB_TYPE_DROPDOWN = 'GET_VESSEL_JOB_TYPE_DROPDOWN'
export const getVesselJobTypeDropdown = (additionalParam) => {
  const extraParam = _.map(additionalParam, (item) => `&${item.properties}=${item.value}`)
  return (dispatch) => {
    dispatch(fetchStart(GET_VESSEL_JOB_TYPE_DROPDOWN))
    services
      .getDropdown('vessel_job_type', extraParam)
      .then((response) =>
        dispatch({
          type: GET_VESSEL_JOB_TYPE_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_VESSEL_JOB_TYPE_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_VESSEL_JOB_TYPE_DROPDOWN)))
  }
}

export const GET_DATE_RANGE_BY_REGION_CODE = 'GET_DATE_RANGE_BY_REGION_CODE' // GET_DATE_RANGE BY REGION_CODE_AND_SUPPLY_BASE
export const getDateRangeByRegionCode = (additionalParam) => {
  const extraParam = _.map(additionalParam, (item) => `&${item.properties}=${item.value}`)
  return (dispatch) => {
    dispatch(fetchStart(GET_DATE_RANGE_BY_REGION_CODE))
    services
      .getDropdown('unscheduled_date_range', extraParam)
      .then((response) =>
        dispatch({
          type: GET_DATE_RANGE_BY_REGION_CODE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_DATE_RANGE_BY_REGION_CODE)))
      .catch(() => dispatch(fetchError(GET_DATE_RANGE_BY_REGION_CODE)))
  }
}

export const GET_ROVING_SERVICE_TYPE = 'GET_ROVING_SERVICE_TYPE'
export const getRovingServiceType = (additionalParam) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_ROVING_SERVICE_TYPE))
    services
      .getDropdown('roving_service_type')
      .then((response) =>
        dispatch({
          type: GET_ROVING_SERVICE_TYPE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_ROVING_SERVICE_TYPE)))
      .catch(() => dispatch(fetchError(GET_ROVING_SERVICE_TYPE)))
  }
}

export const SET_VESSEL_SCHEDULE_INFO = 'SET_VESSEL_SCHEDULE_INFO'
export const setVesselScheduleInfo = (info) => {
  return {
    type: SET_VESSEL_SCHEDULE_INFO,
    payload: info,
  }
}

export const PUT_VESSEL_SCHEDULE = 'PUT_VESSEL_SCHEDULE'
export const putVesselSchedule = (list) => {
  return (dispatch) => {
    dispatch(fetchStart(PUT_VESSEL_SCHEDULE))
    services
      .putVesselSchedule(list)
      .then((response) =>
        dispatch({
          type: PUT_VESSEL_SCHEDULE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(PUT_VESSEL_SCHEDULE)))
      .catch(() => dispatch(fetchError(PUT_VESSEL_SCHEDULE)))
  }
}

export const PUT_VESSEL_SCHEDULE_SIDEBAR = 'PUT_VESSEL_SCHEDULE_SIDEBAR'
export const putVesselScheduleSidebar = (list) => {
  return (dispatch) => {
    dispatch(fetchStart(PUT_VESSEL_SCHEDULE_SIDEBAR))
    services
      .putVesselScheduleSidebar(list)
      .then((response) =>
        dispatch({
          type: PUT_VESSEL_SCHEDULE_SIDEBAR,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(PUT_VESSEL_SCHEDULE_SIDEBAR)))
      .catch(() => dispatch(fetchError(PUT_VESSEL_SCHEDULE_SIDEBAR)))
  }
}

export const POST_CUSTOM_SCENARIO_MODELLING = 'POST_CUSTOM_SCENARIO_MODELLING'
export const postCustomScenarioModelling = (voyageDetailsObject) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_CUSTOM_SCENARIO_MODELLING))
    services
      .postCustomScenarioModelling(voyageDetailsObject)
      .then((response) =>
        dispatch({
          type: POST_CUSTOM_SCENARIO_MODELLING,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_CUSTOM_SCENARIO_MODELLING)))
      .catch(() => dispatch(fetchError(POST_CUSTOM_SCENARIO_MODELLING)))
  }
}

export const GET_UNDERDECK_CARGO_DROPDOWN = 'GET_UNDERDECK_CARGO_DROPDOWN'
export const getUnderdeckCargoDropdown = () => {
  return (dispatch) => {
    dispatch(fetchStart(GET_UNDERDECK_CARGO_DROPDOWN))
    services
      .getDropdown('cargo', [`&cargo_type=Under Deck`])
      .then((response) =>
        dispatch({
          type: GET_UNDERDECK_CARGO_DROPDOWN,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_UNDERDECK_CARGO_DROPDOWN)))
      .catch(() => dispatch(fetchError(GET_UNDERDECK_CARGO_DROPDOWN)))
  }
}

export const DELETE_VOYAGE = 'DELETE_VOYAGE'
export const deleteVoyage = (voyageId, jobType, scheduleHdrId = '') => {
  return (dispatch) => {
    dispatch(fetchStart(DELETE_VOYAGE))
    services
      .deleteVoyage(voyageId, jobType, scheduleHdrId)
      .then((response) =>
        dispatch({
          type: DELETE_VOYAGE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(DELETE_VOYAGE)))
      .catch(() => dispatch(fetchError(DELETE_VOYAGE)))
  }
}

export const GET_VESSEL_CAPACITY = 'GET_VESSEL_CAPACITY'
export const getVesselCapacity = (vesselId, jobType, isImperial = false) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VESSEL_CAPACITY))
    services
      .getVesselCapacity(vesselId, jobType, isImperial)
      .then((response) =>
        dispatch({
          type: GET_VESSEL_CAPACITY,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_VESSEL_CAPACITY)))
      .catch(() => dispatch(fetchError(GET_VESSEL_CAPACITY)))
  }
}

export const GET_VESSEL_CONTRACT_PERIOD = 'GET_VESSEL_CONTRACT_PERIOD'
export const getVesselContractPeriod = (vesselName) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VESSEL_CONTRACT_PERIOD))
    services
      .getVesselContractPeriod(vesselName)
      .then((response) =>
        dispatch({
          type: GET_VESSEL_CONTRACT_PERIOD,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_VESSEL_CONTRACT_PERIOD)))
      .catch(() => dispatch(fetchError(GET_VESSEL_CONTRACT_PERIOD)))
  }
}

export const POST_VESSEL_SCHEDULE_SIDEBAR = 'POST_VESSEL_SCHEDULE_SIDEBAR'
export const postVesselScheduleSidebar = (obj) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VESSEL_SCHEDULE_SIDEBAR))
    services
      .postVesselScheduleSidebar(obj)
      .then((response) =>
        dispatch({
          type: POST_VESSEL_SCHEDULE_SIDEBAR,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VESSEL_SCHEDULE_SIDEBAR)))
      .catch(() => dispatch(fetchError(POST_VESSEL_SCHEDULE_SIDEBAR)))
  }
}

export const RESET_CONFIRM_SCHEDULE_STATUS = 'RESET_CONFIRM_SCHEDULE_STATUS'
export const resetConfirmScheduleStatus = (info) => {
  return {
    type: RESET_CONFIRM_SCHEDULE_STATUS,
    payload: info,
  }
}

export const SET_NEW_VOYAGE_DETAILS = 'SET_NEW_VOYAGE_DETAILS'
export const setNewYoyageDetails = (info) => {
  return {
    type: SET_NEW_VOYAGE_DETAILS,
    payload: info,
  }
}

export const RESET_ADD_DEL_VOYAGE_STATUS = 'RESET_ADD_DEL_VOYAGE_STATUS'
export const resetAddDelVoyageStatus = (info) => {
  return {
    type: RESET_ADD_DEL_VOYAGE_STATUS,
    payload: info,
  }
}

export const RESET_CUSTOM_SCENARIO_MODELLING_STATUS = 'RESET_CUSTOM_SCENARIO_MODELLING_STATUS'
export const resetCustomScenarioModellingStatus = (info) => {
  return {
    type: RESET_CUSTOM_SCENARIO_MODELLING_STATUS,
    payload: info,
  }
}

export const RESET_SCENARIO_MODELLING_STATUS = 'RESET_SCENARIO_MODELLING_STATUS'
export const resetScenarioModellingStatus = (info) => {
  return {
    type: PUT_SCENARIO_MODELLING,
    payload: '',
  }
}

export const RESET_CVS_SIDEBAR_DATA = 'RESET_CVS_SIDEBAR_DATA'
export const resetCvsSidebarData = (info) => {
  return {
    type: RESET_CVS_SIDEBAR_DATA,
    payload: info,
  }
}

export const DELETE_PUBLISHED_VOYAGE = 'DELETE_PUBLISHED_VOYAGE'
export const deletePublishedVoyage = (voyageId, deleteReason) => {
  return (dispatch) => {
    dispatch(fetchStart(DELETE_PUBLISHED_VOYAGE))
    services
      .deletePublishedVoyage(voyageId, deleteReason)
      .then((response) =>
        dispatch({
          type: DELETE_PUBLISHED_VOYAGE,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(DELETE_PUBLISHED_VOYAGE)))
      .catch(() => dispatch(fetchError(DELETE_PUBLISHED_VOYAGE)))
  }
}

export const RESET_VESSEL_SCHEDULE_SIDEBAR = 'RESET_VESSEL_SCHEDULE_SIDEBAR'
export const resetVesselScheduleSidebar = () => {
  return {
    type: RESET_VESSEL_SCHEDULE_SIDEBAR,
    payload: {},
  }
}

export const GET_VOYAGE_SCHEDULE_SEQ_PRIORITY = 'GET_VOYAGE_SCHEDULE_SEQ_PRIORITY'
export const getVoyageScheduleSeqPriority = (tripId) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_VOYAGE_SCHEDULE_SEQ_PRIORITY))
    services
      .getVoyageScheduleSeqPriority(tripId)
      .then((response) =>
        dispatch({
          type: GET_VOYAGE_SCHEDULE_SEQ_PRIORITY,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_VOYAGE_SCHEDULE_SEQ_PRIORITY)))
      .catch(() => dispatch(fetchError(GET_VOYAGE_SCHEDULE_SEQ_PRIORITY)))
  }
}

export const POST_VOYAGE_SCHEDULE_SEQ_PRIORITY = 'POST_VOYAGE_SCHEDULE_SEQ_PRIORITY'
export const postVoyageScheduleSeqPriority = (array) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VOYAGE_SCHEDULE_SEQ_PRIORITY))
    services
      .postVoyageScheduleSeqPriority(array)
      .then((response) =>
        dispatch({
          type: POST_VOYAGE_SCHEDULE_SEQ_PRIORITY,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VOYAGE_SCHEDULE_SEQ_PRIORITY)))
      .catch(() => dispatch(fetchError(POST_VOYAGE_SCHEDULE_SEQ_PRIORITY)))
  }
}

export const PUT_VOYAGE_SCHEDULE_SEQ_PRIORITY = 'PUT_VOYAGE_SCHEDULE_SEQ_PRIORITY'
export const putVoyageScheduleSeqPriority = (array) => {
  return (dispatch) => {
    dispatch(fetchStart(PUT_VOYAGE_SCHEDULE_SEQ_PRIORITY))
    services
      .putVoyageScheduleSeqPriority(array)
      .then((response) =>
        dispatch({
          type: PUT_VOYAGE_SCHEDULE_SEQ_PRIORITY,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(PUT_VOYAGE_SCHEDULE_SEQ_PRIORITY)))
      .catch(() => dispatch(fetchError(PUT_VOYAGE_SCHEDULE_SEQ_PRIORITY)))
  }
}

export const RESET_VOYAGE_SCHEDULE_SEQ_PRIORITY_STATUS = 'RESET_VOYAGE_SCHEDULE_SEQ_PRIORITY_STATUS'
export const resetVoyageScheduleSeqPriorityStatus = (info) => {
  return {
    type: RESET_VOYAGE_SCHEDULE_SEQ_PRIORITY_STATUS,
    payload: info,
  }
}

export const PUT_SCHEDULE_STATUS_D3 = 'PUT_SCHEDULE_STATUS_D3'
export const putScheduleStatusDMinus3 = (array) => {
  return (dispatch) => {
    dispatch(fetchStart(PUT_SCHEDULE_STATUS_D3))
    services
      .putScheduleStatusDMinus3(array)
      .then((response) =>
        dispatch({
          type: PUT_SCHEDULE_STATUS_D3,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(PUT_SCHEDULE_STATUS_D3)))
      .catch(() => dispatch(fetchError(PUT_SCHEDULE_STATUS_D3)))
  }
}

export const GET_SCHEDULE_STATUS_D3_BY_ID = 'GET_SCHEDULE_STATUS_D3_BY_ID'
export const getScheduleStatusDMinus3 = (object) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_SCHEDULE_STATUS_D3_BY_ID))
    services
      .getScheduleStatusDMinus3(object)
      .then((response) =>
        dispatch({
          type: GET_SCHEDULE_STATUS_D3_BY_ID,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_SCHEDULE_STATUS_D3_BY_ID)))
      .catch(() => dispatch(fetchError(GET_SCHEDULE_STATUS_D3_BY_ID)))
  }
}

export const setScheduleStatusD3 = (payload) => {
  return {
    type: GET_SCHEDULE_STATUS_D3_BY_ID,
    payload,
  }
}

export const RESET_SCHEDULE_STATUS_D3 = 'RESET_SCHEDULE_STATUS_D3'
export const resetScheduleStatusD3 = (info) => {
  return {
    type: RESET_SCHEDULE_STATUS_D3,
    payload: info,
  }
}

export const POST_EXECUTE_D_MINUS_3 = 'POST_EXECUTE_D_MINUS_3'
export const postExecuteDMinus3 = (payload, actionType = 'postExecuteDMinus3') => {
  if (actionType === 'resetExecuteDMinus3') {
    return (dispatch) => {
      dispatch({
        type: POST_EXECUTE_D_MINUS_3,
        payload: {},
      })
    }
  }
  return (dispatch) => {
    dispatch(fetchStart(POST_EXECUTE_D_MINUS_3))
    services
      .postExecuteDMinus3(payload)
      .then((response) =>
        dispatch({
          type: POST_EXECUTE_D_MINUS_3,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_EXECUTE_D_MINUS_3)))
      .catch(() => dispatch(fetchError(POST_EXECUTE_D_MINUS_3)))
  }
}

export const GET_DOWNLOAD_SCHEDULE_D_MINUS_3 = 'GET_DOWNLOAD_SCHEDULE_D_MINUS_3'
export const getDownloadScheduleDMinus3 = (tripId) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_DOWNLOAD_SCHEDULE_D_MINUS_3))
    services
      .getDownloadScheduleDMinus3(tripId)
      .then((response) =>
        dispatch({
          type: GET_DOWNLOAD_SCHEDULE_D_MINUS_3,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(GET_DOWNLOAD_SCHEDULE_D_MINUS_3)))
      .catch(() => dispatch(fetchError(GET_DOWNLOAD_SCHEDULE_D_MINUS_3)))
  }
}

export const RESET_DOWNLOAD_SCHEDULE_D_MINUS_3 = 'RESET_DOWNLOAD_SCHEDULE_D_MINUS_3'
export const resetDownloadDMinus3 = (info) => {
  return {
    type: RESET_DOWNLOAD_SCHEDULE_D_MINUS_3,
    payload: info,
  }
}

export const RESET_MAINTENANCE_EDIT_STATUS = 'RESET_MAINTENANCE_EDIT_STATUS'
export const resetMaintenanceEditStatus = (info) => {
  return {
    type: RESET_MAINTENANCE_EDIT_STATUS,
    payload: info,
  }
}

export const RESET_SCENARIO_MODELLING_DEL_STATUS = 'RESET_SCENARIO_MODELLING_DEL_STATUS'
export const resetScenarioModellingDelStatus = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_SCENARIO_MODELLING_DEL_STATUS,
      payload: '',
    })
  }
}

export const RESET_BOOK_MARINE_STATUS = 'RESET_BOOK_MARINE_STATUS'
export const resetBookMarineServiceStatus = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_BOOK_MARINE_STATUS,
      payload: '',
    })
  }
}

export const RESET_POST_VESSEL_SCHEDULE_STATUS = 'RESET_POST_VESSEL_SCHEDULE_STATUS'
export const resetPostVesselScheduleStatus = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_POST_VESSEL_SCHEDULE_STATUS,
      payload: '',
    })
  }
}

export const SET_STATE_OPTIMISE_PAGE = "SET_STATE_OPTIMISE_PAGE";
export const setStateOptimisePage = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: SET_STATE_OPTIMISE_PAGE,
      payload: { [key]: value },
    });
  };
};

export const SET_VESSEL_SELECTED = 'SET_VESSEL_SELECTED'
export const setVesselSelected = (info) => {
  return {
    type: SET_VESSEL_SELECTED,
    payload: info,
  }
}

export const POST_PLT_INTEGRATION = 'POST_PLT_INTEGRATION'
export const POST_PLT_INTEGRATION_D3_OPTIMISE = 'POST_PLT_INTEGRATION_D3_OPTIMISE'
export const PUT_PLT_INTEGRATION_CONFIRM_SCHEDULE = 'PUT_PLT_INTEGRATION_CONFIRM_SCHEDULE'
export const postPLTIntegration = (requestBody) => {
  let dispatchType = "";
  switch (requestBody?.type) {
    case TYPE_OF_PLT_INTEGRATION.putD3OptimizedVoyage:
      dispatchType = POST_PLT_INTEGRATION_D3_OPTIMISE;
      break;

    case TYPE_OF_PLT_INTEGRATION.postVoyagePublish:
      dispatchType = PUT_PLT_INTEGRATION_CONFIRM_SCHEDULE;
      break;

    default:
      dispatchType = POST_PLT_INTEGRATION;
      break;
  }

  return (dispatch) => {
    dispatch(fetchStart(dispatchType))
    services
      .postPLTIntegration(requestBody)
      .then((response) =>
        dispatch({
          type: dispatchType,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(dispatchType)))
      .catch(() => dispatch(fetchError(dispatchType)))
  }
}


export const GET_DEMAND_FORCE_CAST = 'GET_DEMAND_FORCE_CAST'
export const getDemandForceCastPage = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_DEMAND_FORCE_CAST,
      payload: data,
    })
  }
}

export const POST_DEMAND_FORCE_CAST = 'POST_DEMAND_FORCE_CAST'
export const postDemandForecast = (array) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_DEMAND_FORCE_CAST))
    services
      .postDemandForecast(array)
      .then((response) =>
        dispatch({
          type: POST_DEMAND_FORCE_CAST,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_DEMAND_FORCE_CAST)))
      .catch(() => dispatch(fetchError(POST_DEMAND_FORCE_CAST)))
  }
}

export const POST_VOYAGE_ACTIVITY = 'POST_VOYAGE_ACTIVITY'
export const postVoyageActivity = (object) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VOYAGE_ACTIVITY))
    services
      .postVoyageActivity(object)
      .then((response) =>
        dispatch({
          type: POST_VOYAGE_ACTIVITY,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VOYAGE_ACTIVITY)))
      .catch(() => dispatch(fetchError(POST_VOYAGE_ACTIVITY)))
  }
}

export const POST_FETCH_DEMAND_DATA = 'POST_FETCH_DEMAND_DATA'
export const postFetchDemandData = (object) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_FETCH_DEMAND_DATA))
    services
      .postFetchDemandData(object)
      .then((response) =>
        dispatch({
          type: POST_FETCH_DEMAND_DATA,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_FETCH_DEMAND_DATA)))
      .catch(() => dispatch(fetchError(POST_FETCH_DEMAND_DATA)))
  }
}

export const resetFetchDemandData = () => {
  return (dispatch) => {
    dispatch({
      type: POST_FETCH_DEMAND_DATA,
      payload: [],
    })
  }
}

export const POST_VESSEL_SCHEDULE_WEATHER_DATA = 'POST_VESSEL_SCHEDULE_WEATHER_DATA'
export const postVesselScheduleWeatherData = (object) => {
  return (dispatch) => {
    dispatch(fetchStart(POST_VESSEL_SCHEDULE_WEATHER_DATA))
    services
      .postVesselScheduleWeatherData(object)
      .then((response) =>
        dispatch({
          type: POST_VESSEL_SCHEDULE_WEATHER_DATA,
          payload: response,
        })
      )
      .then(() => dispatch(fetchSuccess(POST_VESSEL_SCHEDULE_WEATHER_DATA)))
      .catch(() => dispatch(fetchError(POST_VESSEL_SCHEDULE_WEATHER_DATA)))
  }
}

export const GET_ACTIVITY_LOGS = 'GET_ACTIVITY_LOGS';
export const getActivityLogs = (payload) => {
  return (dispatch) => {
    dispatch(fetchStart(GET_ACTIVITY_LOGS));
    services
      .getActivityLogs(payload)
      .then((response) => 
        dispatch({
          type: GET_ACTIVITY_LOGS,
          payload: response
        })
      )
      .then(() => dispatch(fetchSuccess(GET_ACTIVITY_LOGS)))
      .catch(() => dispatch(fetchError(GET_ACTIVITY_LOGS)))
  }
}
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Button, Popup, Dropdown, Icon } from 'semantic-ui-react'
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser";
import AuthService from '../../../AuthService';
import { ReactSVG } from 'react-svg'

//Redux
import { UAC_REDUCER } from '../../../reducers/common/uacReducer'

//Hooks
import { useStateWithPaths, useActions } from '../../../hooks/useConnect'
import useDisplayNamesMap from '../../../hooks/useDisplayNamesMap'

// Icon
import MyProfileIconWhite from '../../../assets/images/stear-user-white.svg'
import ChevronDown from '../../../assets/images/chevron-down.svg'

// Actions
import * as UacActions from '../../../actions/common/actionUac'
import * as UacPageActions from '../../../actions/common/actionUac'

// Component
import FetchStateWrapper from '../../../components/fetch-state-wrapper'

import { isMobileScreen } from '../../../helpers/isMobileScreen'
import { hasSentinelPermission } from '../../../utils/moduleLevelPermission' 

const PopupContent = ({isOpenEditForm, setIsOpenEditForm, handlePopUpClose, tenants }) => {
  const [uacData] = useStateWithPaths([`${UAC_REDUCER}`])
  const displayRegionNameMap = useDisplayNamesMap('REGION_NAME');
  const { inProgress: msalInProgress } = useMsal();

  const {
    putCurrentUserAccessControl,
    putSwitchCompanyForSuperAdmin
  } = useActions({
    putCurrentUserAccessControl: UacPageActions.putCurrentUserAccessControl,
    putSwitchCompanyForSuperAdmin: UacPageActions.putSwitchCompanyForSuperAdmin,
  })

  const [company, setCompany] = useState(uacData?.uac?.data?.division_id)
  const [currentAccessUser, setCurrentAccessUser] = useState({})
  const isDisableSaveBtn = currentAccessUser.division_id === company
  const [platforms, setPlatforms] = useState();
  const [isShowPlatform, setIsShowPlatform] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const logout = async () => {
    if (msalInProgress === InteractionStatus.None) {
      const auth = new AuthService();
      await auth.logout();
    } 
  }
  
  const onSaveEditProfile = () => {
    // Previous code TODO: remove after testing
    // putCurrentUserAccessControl(
    //   currentAccessUser.user_id, 
    //   currentAccessUser.display_name,
    //   currentAccessUser.email,
    //   company,
    //   currentAccessUser?.roles?.map(role => role.role_id)  ?? [],
    //   currentAccessUser?.regions?.map(region => region.region_id) ?? [],
    //   currentAccessUser?.user_locations?.map(location => location.location_name) ?? [],
    //   currentAccessUser?.iap_planners?.map(iap => iap.user_id) ?? [],
    //   currentAccessUser?.booking_focals?.map(focal => focal.user_id) ?? []
    // )
    putSwitchCompanyForSuperAdmin(company);
  }

  const setPlatformsBasedOnRoles = () => {
    const checkIsSuperAdmin = uacData?.uac?.data?.roles?.find(
      (role) => role?.role_name === 'Super Admin',
    );

    const checkIsTenantAdmin = uacData?.uac?.data?.roles?.find(
      (role) => role?.role_name === 'Tenant Admin',
    );
    
    const defaultPlatforms = [
      {
        name: 'Platform+ Portal',
        path: `${window.location.href}`,
      },
    ];
    if (Boolean(checkIsSuperAdmin)) {
      setPlatforms(
        [
          { 
            name: 'Super Admin Portal', 
            path: `${process.env.REACT_APP_SUPER_ADMIN_PORTAL}`,
          },
          {
            name: 'Tenant Admin Portal',
            path: `${process.env.REACT_APP_TENANT_ADMIN_PORTAL}`,
          },
          ...defaultPlatforms,
          {
            name: 'Sentinel Portal',
            path: `${process.env.REACT_APP_SENTINEL_PORTAL}`,
          },
        ]);
    }else {
      setPlatforms(
        [
          ...(Boolean(checkIsTenantAdmin) ?
          [{
            name: 'Tenant Admin Portal',
            path: `${process.env.REACT_APP_TENANT_ADMIN_PORTAL}`,
          }] : []),
          ...defaultPlatforms,
          ...(hasSentinelPermission()
          ? [
            {
              name: 'Sentinel Portal',
              path: `${process.env.REACT_APP_SENTINEL_PORTAL}`,
            },
          ]
          : []),
          
        ]);
    }
  }

  const isAdmin = () => {
    const checkIsSuperAdmin = uacData?.uac?.data?.roles?.find(
      (role) => role?.role_name === 'Super Admin',
    );

    const checkIsTenantAdmin = uacData?.uac?.data?.roles?.find(
      (role) => role?.role_name === 'Tenant Admin',
    );

    return Boolean(checkIsSuperAdmin) || Boolean(checkIsTenantAdmin);
  }

  useEffect(() => {
    if (!_.isEmpty(uacData?.uac?.data)) {
      setCurrentAccessUser(uacData?.uac?.data)
      if (!isMobile) {
        setIsOpenEditForm(false)
      }
      setPlatformsBasedOnRoles()
      const checkIsSuperAdmin = uacData?.uac?.data?.roles?.find(
        (role) => 'Super Admin' === role?.role_name,
      );
      setIsSuperAdmin(Boolean(checkIsSuperAdmin));
    }
  }, [uacData?.uac])

  const isMobile = isMobileScreen();

  return (
    <>
      <div className="profile-popup-wrapper">
        {isMobile ? 
          (
            <div className={`header`}>
              <div className={`medium-text-bold mar-bottom-2`}>Hello, {_.get(currentAccessUser, 'display_name')}</div>
              <div>
                <Icon onClick={handlePopUpClose} name='close' color='grey' size='large' />
              </div>
            </div>
          ):
          (
            <div className={`medium-text-bold mar-bottom-2`}>Hello, {_.get(currentAccessUser, 'display_name')}</div>
          )
        }
        <div className={`mar-y-2`}>
          <div className={`medium-small-text-grey`}>Email:</div>
          <div className={`medium-small-text-black`}>{_.get(currentAccessUser, 'email')}</div>
        </div>

        {!isMobile && <div>
          <div className='flex'>
            <div>Switch Platform</div>
            {isShowPlatform ? (
              <Icon name="angle up" style={{ color: '#5C5C7A' }} onClick={() => setIsShowPlatform(false)} />
            ) : (
              <Icon name="angle down" style={{ color: '#5C5C7A' }} onClick={() => setIsShowPlatform(true)} />
            )}
          </div>
          <div className='platform-list'>
            {isShowPlatform ? (
              platforms?.map((platform, index) => (
                <span
                  onClick={() => {
                    localStorage.clear()
                    window.location.href = platform.path;
                  }}
                  key={`platform-${index}`}
                  className={`platform-item`}
                  style={{
                    color: platform?.name === 'Platform+ Portal' ? '#0000A1' : '#757595',
                  }}
                >
                  {platform.name}
                  { platform?.name === 'Platform+ Portal' && (
                    <Icon name="check circle outline" style={{ color: '#0000A1', marginLeft: '3px' }} />
                  )}
                </span>
              ))
            ) : (
              <span className={`platform-item`}>Platform+ Portal</span>
            )}
          </div>
        </div>}

        <div className={`mar-y-2`}>
          <div className={`medium-small-text-grey`}>Role:</div>
          <div className='list-role'>
            {_.map(_.get(currentAccessUser, 'roles'), (item, roleIndex) => (
              <span key={`uac-${roleIndex}`} className={`role-item`}>{item.role_name}</span>
            ))}
          </div>
        </div>
        <div className={`mar-y-2`}>
          <div className={`medium-small-text-grey`}>Company:</div>
          {isMobile && isSuperAdmin ? 
            <Dropdown
              className={`form-dropdown`}
              selectOnBlur={false}
              selection
              options={tenants}
              onChange={(e, data) => setCompany(data.value)}
              defaultValue={_.get(currentAccessUser, 'division_id')}
              icon={<ReactSVG src={ChevronDown} />}
            />
          :
          isOpenEditForm
            ? <div>
              <Dropdown
                className={`form-dropdown`}
                selectOnBlur={false}
                selection
                options={tenants}
                onChange={(e, data) => setCompany(data.value)}
                defaultValue={_.get(currentAccessUser, 'division_id')}
                icon={<ReactSVG src={ChevronDown} />}
              />
            </div>
            : <div>{_.get(currentAccessUser, 'division_name')}</div>
          }
        </div>
        {
          !isAdmin() && (
            <>
              <div className={`mar-y-2`}>
                <div className={`medium-small-text-grey`}>Region:</div>
                <div>
                  {
                    <span className={`item`}>
                      {_.get(currentAccessUser, "regions")
                        ?.map((r) => displayRegionNameMap[r.region_name] ?? r.region_name,)
                        ?.join(", ")}
                    </span>
                  }
                </div>
              </div>
              <div className={`mar-y-2`}>
                <div className={`medium-small-text-grey`}>Location:</div>
                <div>
                  {
                    <span className={`item`}>
                      {_.get(currentAccessUser, "all_locations")
                        ?.map((r) => r.location_name)
                        ?.join(", ")}
                    </span>
                  }
                </div>
              </div>
            </>
          )
        }

        {isMobile ? 
          <div className={`mobile-btn-container`}>
            {isSuperAdmin && <Button className="mobile-btn mobile-with-background" style={{ width: '97px' }} onClick={() => onSaveEditProfile()}>Save</Button>}
            <Button className="mobile-btn mobile-with-background" onClick={logout}>Sign Out</Button>
          </div>
          :
          <div className={`btn-container mar-top-2`}>
            {
              isOpenEditForm
              ? <>
                <Button className="btn with-border" onClick={() => setIsOpenEditForm(false)}>Cancel</Button>
                <Button className="btn with-background" onClick={() => onSaveEditProfile()} disabled={isDisableSaveBtn}>Save</Button>
              </>
              : <>
                {(isSuperAdmin || (!isSuperAdmin && uacData?.uac?.data?.linked_divisions && uacData?.uac?.data?.linked_divisions.length > 1)) && <Button className="btn with-border" onClick={() => setIsOpenEditForm(true)}>Edit Profile</Button>}
                <Button className="btn with-background" onClick={logout}>Sign Out</Button>
              </>
            }
          </div>
        }
      </div>
    </>
  )
}

const ProfileMenu = (props) => {
  const {
    postUserAccess,
    getTenantList
  } = useActions({
    postUserAccess: UacActions.postUserAccess,
    getTenantList: UacPageActions.getTenantList,
  })

  const [isOpenEditForm, setIsOpenEditForm] = useState(false)
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [uacData] = useStateWithPaths([`${UAC_REDUCER}`]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [tenants, setTenants] = useState([]);

  useEffect(() => {
    postUserAccess()
  }, [postUserAccess])

  useEffect(() => {
    const checkIsSuperAdmin = uacData?.uac?.data?.roles?.find((role) => role?.role_name === 'Super Admin');
    setIsSuperAdmin(Boolean(checkIsSuperAdmin));
  }, [uacData]);


  const isMobile = isMobileScreen();

  const handleFetchTenantList = () => {
    if (!isSuperAdmin) {
      const tenantsDropdown =  uacData?.uac?.data?.linked_divisions?.map((tenant) => { 
        return {
          "value": tenant.division_id, 
          "text": tenant.division_name,
          "key": tenant.division_id,
        }
      });
      setTenants(tenantsDropdown);
    } else {
      getTenantList()   
    }
  }

  useEffect(() => {
    if (!_.isEmpty(uacData?.tenants)) {
      const tenantsDropdown = uacData?.tenants?.map((tenant) => { 
        return {
          "value": tenant.division_id, 
          "text": tenant.division_name,
          "key": tenant.division_id,
        }
      });
      setTenants(tenantsDropdown);
    }
  }, [uacData?.tenants])
  
  const handlePopUpOPen = () => {
    setIsOpenPopup(true);
    handleFetchTenantList()
    props.handleSetIsSidebarOpen(false);
  }

  const handlePopUpClose = () => {
    // setIsOpenEditForm(false);
    setIsOpenPopup(false);
  }

  return (
    <div className={`${isMobile ? `mobile-profile-menu-container` : `profile-menu-container`} flex column`}>
      <div className={`flex end`}>
        <Popup
          trigger={<ReactSVG src={MyProfileIconWhite} style={{ cursor: 'pointer' }} />}
          content={isMobile ? <PopupContent handlePopUpClose={handlePopUpClose} tenants={tenants}/> : <PopupContent isOpenEditForm={isOpenEditForm} setIsOpenEditForm={setIsOpenEditForm} tenants={tenants}/>}
          style={{ zIndex: '9999' }}
          pinned
          basic
          offset="0,20"
          on="click"
          position={`bottom ${isMobile ? `center` : `right`}`}
          className={isMobile ? `mobile-profile-popup-container` : `profile-popup-container`}
          onOpen={isMobile ? handlePopUpOPen : () => handleFetchTenantList()}
          onClose={isMobile ? handlePopUpClose : () => setIsOpenEditForm(false)}
          flowing={isMobile ? true : false}
          open={isMobile ? isOpenPopup : undefined}
        />
        <FetchStateWrapper id={UacPageActions.PUT_CURRENT_USER_ACCESS_CONTROL} />
        <FetchStateWrapper id={UacPageActions.SWITCH_COMPANY_FOR_SUPER_ADMIN} />
      </div>
    </div>
  )
}

export default ProfileMenu
